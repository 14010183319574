
$table-head-bg:               $white;
$table-head-color:            $dark;

$table-accent-bg:             rgba($black, .05);
$table-bg: null;
$table-bg-level: -9;
$table-caption-color: $text-muted;
$table-color: $dark;
$theme-color-interval:      8%;
$table-striped-order: odd;

$table-hover-color: $table-color;
$table-hover-bg: $cloud;

$table-active-bg: $table-hover-bg;

$table-border-color:          $smoke;
$table-border-level:          -6;
$table-border-width:          1px;

$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .3rem;

$table-dark-color: $white;
$table-dark-bg:               $slate;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-color:      $table-dark-color;
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($table-dark-bg, 7.5%);
