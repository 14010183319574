.#{$prefix}img {
  &-xs {
    width: 24px;
    height: 24px;
  }
  &-sm {
    width: 40px;
    height: 40px;
  }
  &-md {
    width: 60px;
    height: 60px;
  }
  &-lg {
    width: 120px;
    height: 120px;
  }
  &-blank-user {
    display: inline-block;
    vertical-align: middle;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIwcHgiIGhlaWdodD0iMTIwcHgiIHZpZXdCb3g9IjAgMCAxMjAgMTIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ni4zICg4MTcxNikgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+IEF2YXRhci8gUGhvdG8gLyAxMjA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iLi8tQXZhdGFyLy1QaG90byIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9IlBlb3BsZXMvLUJsYW5rLVBob3RvIj4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJCYXNlIiBmaWxsPSIjRTJFMkUyIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCI+PC9yZWN0PgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTYxLjA1ODgyMzUsNzAuNTg4MjM1MyBDNDkuMzYzMzgxOCw3MC41ODgyMzUzIDM5Ljg4MjM1MjksNjEuMTA3MjA2NSAzOS44ODIzNTI5LDQ5LjQxMTc2NDcgQzM5Ljg4MjM1MjksMzcuNzE2MzIyOSA0OS4zNjMzODE4LDI4LjIzNTI5NDEgNjEuMDU4ODIzNSwyOC4yMzUyOTQxIEM3Mi43NTQyNjUzLDI4LjIzNTI5NDEgODIuMjM1Mjk0MSwzNy43MTYzMjI5IDgyLjIzNTI5NDEsNDkuNDExNzY0NyBDODIuMjM1Mjk0MSw2MS4xMDcyMDY1IDcyLjc1NDI2NTMsNzAuNTg4MjM1MyA2MS4wNTg4MjM1LDcwLjU4ODIzNTMgWiBNNTYuNDcwNTg4Miw3Ny42NDcwNTg4IEw2My41Mjk0MTE4LDc3LjY0NzA1ODggQzgzLjAyMTgxNDcsNzcuNjQ3MDU4OCA5OC44MjM1Mjk0LDkzLjQ0ODc3MzUgOTguODIzNTI5NCwxMTIuOTQxMTc2IEw5OC44MjM1Mjk0LDEyMCBMMjEuMTc2NDcwNiwxMjAgTDIxLjE3NjQ3MDYsMTEyLjk0MTE3NiBDMjEuMTc2NDcwNiw5My40NDg3NzM1IDM2Ljk3ODE4NTMsNzcuNjQ3MDU4OCA1Ni40NzA1ODgyLDc3LjY0NzA1ODggWiIgaWQ9IlBhdGgiIGZpbGw9IiNCNEI0QjQiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}


