// Popovers
$zindex-popover:                    1060;
$popover-font-size:                 $font-size-base;
$popover-bg:                        $white;
$popover-max-width:                 480px;
$popover-border-width:              0;
$popover-border-color:              $white;
$popover-border-radius:             4px;
$popover-box-shadow:                0 4px 8px rgba(119, 119, 119, .1), 0 12px 20px rgba(119, 119, 119, .2);

$popover-header-bg:                 $white;
$popover-header-color:              null;
$popover-header-padding-y:          1rem;
$popover-header-padding-x:          1rem;

$popover-body-color:                $body-color;
$popover-body-padding-y:            $popover-header-padding-y;
$popover-body-padding-x:            $popover-header-padding-x;

$popover-arrow-width:               0;
$popover-arrow-height:              .5rem;
$popover-arrow-color:               $popover-bg;

$popover-arrow-outer-color:         $white;
