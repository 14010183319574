// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               8px;
$btn-padding-x:               16px;
$btn-font-size:               14px;
$btn-line-height:             20px;

$btn-padding-y-sm:            2px;
$btn-padding-x-sm:            8px;
$btn-font-size-sm:            14px;
$btn-line-height-sm:          20px;

// $btn-padding-y-lg:            8px;
// $btn-padding-x-lg:            16px;
$btn-font-size-lg:            16px;
$btn-line-height-lg:          24px;

$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-normal;
$btn-border-width: 1px;
$btn-border-radius: 4px;
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-focus-box-shadow: 0 0 4px 1px $link;
$btn-disabled-opacity: .65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width: .2rem;

$btn-link-disabled-color: $cloud;
$btn-padding-y-lg: 8px;
$btn-padding-x-lg: 16px;
$btn-border-radius-lg: 4px;
$btn-border-radius-sm: 4px;
$btn-block-spacing-y: .5rem;
