
// List group

$list-group-color:                  null;
$list-group-bg:                     $white;
$list-group-border-color:           rgba($black, .125);
$list-group-border-width:           1px;
$list-group-border-radius:          4px;

$list-group-item-padding-y:         .75rem;
$list-group-item-padding-x:         1.25rem;

$list-group-hover-bg:               $gray-100;
$list-group-active-color:           $white;
$list-group-active-bg:              $primary;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-bg:            $list-group-bg;

$list-group-action-color:           $gray-700;
$list-group-action-hover-color:     $list-group-action-color;

$list-group-action-active-color:    $body-color;
$list-group-action-active-bg:       $gray-200;
