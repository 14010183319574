%font-size-base {
  font-size: $font-size-base; // 16px
  line-height: 20px;
  letter-spacing: $letter-spacing;
}

%font-size-sm {
  font-size: $font-size-sm; // 12px
  line-height: 16px;
  letter-spacing: $letter-spacing;
}
