.#{$prefix}pagination {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      color: $slate;
      list-style-type: none;
      .ic {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }

  &_limit {
    ul {
      li {
        margin-right: 6px;
        color: $slate;
        &:last-child {
          margin-right: 0;
        }
        .custom-select {
          width: 75px;
          padding: 4px 8px;
          margin: 0 4px 0 0;
        }
      }
    }
  }
  &_pages {
    li {
      .#{$prefix}btn {
        max-width: 20px;
        max-height: 20px;
        padding: 0;
        font-size: 20px;
        border: 0;
        &:hover {
          text-decoration: none;
        }
      }
      input {
        width: 50px;
        margin: 0 8px 0 4px;
      }
    }
  }

  &_total_pages {
    margin: 0 6px;
  }
}
