// v1.2 color
$alert-background-theme-colors: ();
$alert-background-theme-colors: map-merge(
  (
    "danger"    : #f9e2de,
    "success"   : #d9f1e7,
    "info"      : #d9e7f5,
    "warning"   : #fff5dc,
  ),
  $alert-background-theme-colors
);

$alert-foreground-theme-colors: ();
$alert-foreground-theme-colors: map-merge(
  (
    "danger"    : map-get($theme-colors, "danger"),
    "success"   : map-get($theme-colors, "success"),
    "info"      : map-get($theme-colors, "info"),
    "warning"   : map-get($theme-colors, "warning"),
  ),
  $alert-foreground-theme-colors
);

@each $color, $value in $alert-foreground-theme-colors {
  $alert-background-color: map-get($alert-background-theme-colors, $color);
  .#{$prefix}alert-#{$color} {
    color: $value;
    background-color: $alert-background-color;
    border: none;
  }
  .#{$prefix}alert-toast.#{$prefix}alert-#{$color} {
    border: 1px solid $value;
  }
}

.#{$prefix}alert-info {
  color: $primary;
  background-color: rgba($primary, .15);
  border: none;
}

.#{$prefix}alert-toast.#{$prefix}alert-info {
  border: 1px solid $primary;
}

.#{$prefix}alert-error {
  color: $danger;
  background-color: rgba($danger, .15);
  border: none;
}

.#{$prefix}alert-toast.#{$prefix}alert-error {
  border: 1px solid $danger;
}

.#{$prefix}alert {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 12px;
  font-size: 0;

  .#{$prefix}alert-icon {
    flex-basis: 24px;
    width: 24px;
    margin-right: 12px;
    font-size: 14px;
    vertical-align: top;
    .ic {
      color: inherit !important; // stylelint-disable-line declaration-no-important
    }
  }

  .#{$prefix}alert-content {
    display: inline-block;
    font-size: 14px;
    color: $dark;
    vertical-align: top;
  }

  .#{$prefix}alert-icon + .#{$prefix}alert-content {
    flex: 1 1 0;
  }

  .#{$prefix}alert-title {
    font-weight: $font-weight-bold;
    line-height: 20px;
  }

  .#{$prefix}alert-description {
    line-height: 20px;
  }

  .#{$prefix}alert-title + .#{$prefix}alert-description {
    padding-top: 4px;
  }

  .#{$prefix}alert-link {
    display: block;
    padding-top: 16px;
    font-weight: 400;
    color: $link !important; // stylelint-disable-line declaration-no-important
    background-color: transparent;
  }

  .close {
    margin-left: 8px;
  }
}

.#{$prefix}alert.#{$prefix}alert-dismissible {
  .close {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    color: $slate;
    .ic {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      vertical-align: middle;
    }
  }
}

.#{$prefix}alert.#{$prefix}alert-small {
  display: inline-flex;
  min-width: 400px;
  .#{$prefix}alert-content {
    display: flex;
  }
  .#{$prefix}alert-description {
    display: inline-block;
  }
  .#{$prefix}alert-link {
    display: inline-block;
    padding: 0;
    padding-left: 16px;
    font-weight: 400;
    color: $link !important; // stylelint-disable-line declaration-no-important
    background-color: transparent;
  }
}

.#{$prefix}alert.#{$prefix}alert-toast {
  display: inline-flex;
  padding: 12px;
  font-size: 0;
  background-color: $white;
  border-radius: 4px; // stylelint-disable-line property-blacklist
  box-shadow: 0 4px 8px 0 rgba($slate, .1), 0 12px 20px 0 rgba($slate, .2);
  .#{$prefix}alert-icon {
    margin-right: 8px;
  }
  .#{$prefix}alert-description {
    display: inline-block;
  }
  .#{$prefix}alert-link {
    display: inline-block;
    padding: 0;
    padding-left: 16px;
    font-weight: 400;
    color: $link !important; // stylelint-disable-line declaration-no-important
    background-color: transparent;
  }
}
