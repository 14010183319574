// Tabs
$nav-tabs-border-color:             $smoke;
$nav-tabs-border-width:             1px;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-link-active-color:        $dark;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: transparent;

$nav-link-padding-y: 8px;
$nav-link-padding-x: 16px;
$nav-link-height: calc($font-size-base * $line-height-base) + $nav-link-padding-y * 2;
$navbar-brand-height:               $font-size-lg * $line-height-base;
$navbar-brand-padding-y:            calc($nav-link-height - $navbar-brand-height) / 2;
$nav-link-disabled-color: $text-muted;
$nav-pills-border-radius: 4px;

$nav-pills-link-active-color:       $white;
$nav-pills-link-active-bg:          $primary;
