@mixin scroll-default {
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $slate;
    background-clip: padding-box;
    border: 4px solid transparent;
    @include border-radius(16px);
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    @include border-radius(4px);
  }
}
