.opacity {
  &--transparent {
    opacity: 0;
  }
  &--opaque {
    opacity: 1;
  }
}

.outline {
  &--none {
    outline: none !important; // stylelint-disable-line declaration-no-important
  }
}
