$font-family-roboto:                  "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-family-base:                    $font-family-roboto;
$font-family-monospace: monospace;

// 1 rem = 16px
$font-size-base:                      .875rem; // 14px
$font-size-lg:                        1.25rem; // 20px
$font-size-sm:                        .75rem; // 12px

$line-height-base:                    1.4285; // The closest value to Mekari design
$line-height-lg:                      1.4285;
$line-height-sm:                      1.4285;
$letter-spacing:                      .2px;

$font-weight-lighter:                 400;
$font-weight-light:                   400;
$font-weight-normal:                  400;
$font-weight-base:                    400;
$font-weight-bold:                    500;
$font-weight-bolder:                  500;

$dt-font-weight: $font-weight-bold;

// Text color
$body-color:                          $dark;
$link-color:                          $blue;
$link-hover-color:                    darken($link-color, 15%);
$link-decoration:                     none;
$link-hover-decoration:               underline;
$h5-font-size:                        16px;

$input-placeholder-color:             $text-muted;
$custom-select-disabled-color:        $text-muted;
$custom-control-label-disabled-color: $text-muted;
