.#{$prefix}badge {
  font-size: $font-size-sm;
  line-height: 1.25;
  vertical-align: middle;
  @extend %font-size-sm;

  .ic {
    width: 12px;
    height: 12px;
    font-size: 14px;
    line-height: 12px;
    color: inherit;
    vertical-align: middle;
    cursor: pointer;
    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: 12px;
    }
  }

  &-font {
    &--circle {
      width: 24px;
    }
    &--rounded {
      width: auto;
    }
  }
  &-shape {
    &--circle {
      width: 24px;
      padding: 4px;
      border-radius: 50%; // stylelint-disable-line property-blacklist
    }
    &--rounded {
      width: auto;
      border-radius: 15px; // stylelint-disable-line property-blacklist
    }
  }

  &-sm {
    padding: 0 4px;
    &.#{$prefix}badge-shape {
      &--circle {
        width: 16px;
        padding: 0;
      }
    }
  }

  &-dot,
  &-dot:empty {
    display: inline-block;
    width: 8px;
    height: 8px;
    padding: 0;
  }
}
