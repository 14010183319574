// Roboto as default Mekari fontfamily
$font-family: "Roboto";
$fonts: (
  300: "Roboto-Regular",
  400: "Roboto-Regular",
  500: "Roboto-Medium",
  700: "Roboto-Medium",
  900: "Roboto-Medium"
);

$font-path: #{"../../fonts/Roboto/"} !default;
@if $is-using-rubygems {
  $font-path: #{"fonts/Roboto/"};
}

@if $is-using-gcs-font-path {
  $font-path: "https://storage.googleapis.com/mekari-ui-toolkit-assets/Roboto/";
}

@each $font-weight, $font-type in $fonts {
  $src: #{$font-path}#{$font-type};
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: #{$font-weight};
    src: local($font-family);
    src: url("#{$src}.ttf") format("truetype");
  }
}
