// Button related variable
%btn-link-active {
  color: $blue;
  background-color: transparent;
  border-color: transparent;
}

%btn-ghost {
  color: $secondary;
  background-color: transparent;
  border: 1px solid transparent;
  &:not(:disabled):not(.disabled):hover {
    background-color: $cloud;
    border-color: $cloud;
  }
  &:not(:disabled):not(.disabled):focus {
    background-color: transparent;
    border-color: $blue;
    box-shadow: 0 0 4px 1px $blue;
  }
}

$btn-secondary-border-color: $smoke;

%btn-secondary-theme {
  color: $secondary;
  background-color: $light;
  border: 1px solid $btn-secondary-border-color;
}

// End of button related variable

.#{$prefix}btn {
  &.#{$prefix}btn-basic,
  &.#{$prefix}btn-secondary {
    @extend %btn-secondary-theme;
    &:not(:disabled):not(.disabled):hover {
      border-color: $sapphire;
    }
    &:not(:disabled):not(.disabled):focus {
      border-color: $sapphire;
      box-shadow: 0 0 4px 1px $sapphire;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .#{$prefix}show > &.dropdown-toggle {
      @extend %btn-secondary-theme;
    }
  }

  &.#{$prefix}btn-ghost {
    @extend %btn-ghost;
    &:not(:disabled):not(.disabled):focus {
      background-color: transparent;
      border-color: $primary;
      box-shadow: 0 0 4px 1px $primary;
    }
  }

  &.#{$prefix}btn-link {
    height: auto;
    padding: 0 4px;
    // stylelint-disable-next-line property-blacklist
    border-radius: 2px;
    @extend %btn-link-active;
    &:not(:disabled):not(.disabled):active {
      @extend %btn-link-active;
    }
    &:not(:disabled):not(.disabled):focus {
      text-decoration: none;
      box-shadow: 0 0 0 2px rgba($blue, .3);
    }
    &:disabled,
    &.disabled {
      text-decoration: none;
      pointer-events: auto;
      background-color: transparent;
      border: none;
    }
  }

  &.#{$prefix}btn-primary {
    &.#{$prefix}btn-with-icon {
      .ic {
        &::before {
          // stylelint-disable-next-line declaration-no-important
          color: $white !important;
        }
      }
    }

    &.#{$prefix}btn-icon-only {
      .ic {
        &::before {
          // stylelint-disable-next-line declaration-no-important
          color: inherit !important;
        }
      }
    }
  }

  &.#{$prefix}is-loading {
    position: relative;
    min-height: 38px;
    pointer-events: none;
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      content: "";
      background-color: inherit;
      // stylelint-disable-next-line property-blacklist
      border-radius: 4px;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 4;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: auto;
      content: "";
      background: center center no-repeat;
      background-size: cover;
      border: 2px solid $white;
      border-right-color: transparent;
      // stylelint-disable-next-line property-blacklist
      border-radius: 50%;
      animation: spinner-border .75s linear infinite;
    }

    &.#{$prefix}btn-basic,
    &.#{$prefix}btn-secondary {
      &::after {
        border-color: $secondary;
        border-right-color: transparent;
      }
    }
    &.#{$prefix}btn-ghost {
      color: transparent;
      &::after {
        border-color: $secondary;
        border-right-color: transparent;
      }
    }
  }

  &.#{$prefix}btn-with-icon {
    .ic,
    span {
      vertical-align: top;
      border: none;
    }
    .ic {
      width: 20px;
      height: 20px;
      padding: 0;
      font-size: 16px;
      color: inherit;
      &.is-left {
        margin-right: 8px;
      }
      &.is-right {
        margin-left: 8px;
      }
    }
    &.#{$prefix}btn-sm {
      .ic,
      span {
        line-height: 16px;
        vertical-align: baseline;
        border: none;
      }
      .ic,
      .is-left {
        margin-right: 0;
      }
      .is-right {
        margin-left: 0;
      }
    }
    &.#{$prefix}btn-lg {
      .ic,
      span {
        vertical-align: top;
        border: none;
      }
      .ic {
        width: 24px;
        height: 24px;
        padding: 0;
        font-size: 20px;
      }
      span {
        line-height: 24px;
      }
    }
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
    cursor: not-allowed;
    background-color: $cloud;
    border: 1px solid $cloud;
    opacity: 1;
    &.#{$prefix}btn-ghost {
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Caret
  &.#{$prefix}dropdown-toggle {
    &::before {
      margin-right: 16px;
    }
    &::after {
      margin-left: 16px;
    }
  }

  // Button group
  &-group {
    .#{$prefix}btn:first-child:not(:only-child):not(.#{$prefix}dropdown-toggle) {
      padding-right: 8px;
    }

    .#{$prefix}btn:last-child:not(:only-child) {
      padding-left: 8px;
    }

    // Caret
    .#{$prefix}dropdown-toggle-split {
      min-width: 38px;
      padding-right: 8px;
      padding-left: 8px;
      &::before {
        margin-right: 0;
      }
      &::after {
        margin-left: 0;
      }

      // Button secondary
      &.#{$prefix}btn-basic:not(:hover):not(:focus),
      &.#{$prefix}btn-secondary:not(:hover):not(:focus) {
        padding-left: 9px;
        border-left: none;
      }
    }

    // Button secondary
    &.#{$prefix}dropleft {
      .#{$prefix}dropdown-toggle-split {
        &.#{$prefix}btn-basic,
        &.#{$prefix}btn-secondary:not(:hover):not(:focus) {
          padding-left: 8px;
          border-left: 1px solid $btn-secondary-border-color;
        }
      }

      ~ .#{$prefix}btn-basic:not(:hover):not(:focus),
      ~ .#{$prefix}btn-secondary:not(:hover):not(:focus) {
        padding-left: 9px;
        border-left: none;
      }
    }

    // Button link active
    &.#{$prefix}show {
      > .#{$prefix}btn-link {
        @extend %btn-link-active;
      }
    }
  }
}

.btn {
  &.btn-sm {
    height: 24px;
  }
  &.btn-lg {
    height: 40px;
  }
  &.#{$prefix}btn-icon-only {
    width: 38px;
    height: 38px;
    padding: 8px;
    font-size: 18px;
    line-height: 18px;
    @extend %btn-ghost;

    > .ic {
      width: 18px;
      height: 18px;
      padding: 0;
      font-size: 18px;
    }
  }
}

.btn-with-badge {
  &__wrapper {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    .btn {
      position: relative;
      width: 38px;
      height: 38px;
      padding: 4px;
      .ic {
        width: 24px !important; // stylelint-disable-line declaration-no-important
        height: 24px !important; // stylelint-disable-line declaration-no-important
        font-size: 24px !important; // stylelint-disable-line declaration-no-important
        line-height: 24px !important; // stylelint-disable-line declaration-no-important
      }
    }
    .badge {
      position: relative;
      right: 16px;
      bottom: 0;
    }
  }
}
