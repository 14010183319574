$input-margin-y: 4px;
$custom-input-line-height: 20px;
$custom-input-padding-y: 8px;
$custom-input-padding-x: 12px;
$custom-input-file-label-padding: 8px;

// general form-control class for inputs (text, password, email, etc)
.#{$prefix}form-control {
  padding: $custom-input-padding-y $custom-input-padding-x;
  margin-top: $input-margin-y;
  margin-bottom: $input-margin-y;
  line-height: $custom-input-line-height;
  color: $dark;

  &:hover:not(:disabled):not([readonly]) {
    border: 1px solid $input-focus-border-color;
  }

  &:disabled,
  &[readonly] {
    color: $muted;
    cursor: not-allowed;
    background-color: $cloud;
    border-color: $smoke;
    outline: none;
    box-shadow: none;
  }

  &:focus:not(:disabled):not([readonly]) {
    color: $dark;
  }

  &.prefix-icon {
    padding-left: 36px;
  }

  &.suffix-icon {
    padding-right: 36px;
  }

  &.with-reset-icon, // will be deprecated in future version
  &.suffix-icon--double {
    padding-right: 36px;
    &.suffix-icon:hover {
      padding-right: 72px;
    }
  }

  &.is-invalid,
  &:invalid {
    border-color: $danger;
  }
}

//plain text class
.#{$prefix}form-control-plaintext {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

// supress magrin on .#{$prefix}form-group labels
.#{$prefix}form-group {
  label {
    margin-bottom: 0;
  }
}

// supress margin on labels inside form-row
.#{$prefix}form-row {
  label {
    margin-bottom: 0;
  }
}


.#{$prefix}custom-select,
.#{$prefix}custom-file-input {
  height: $input-height;
  padding: $custom-select-padding-y 8px $custom-select-padding-y 12px;
  margin: $input-margin-y 0;
  line-height: $custom-input-line-height;
  color: $dark;

  &:hover:not(:disabled) {
    cursor: pointer;
    border: 1px solid $input-focus-border-color;
  }

  &:focus {
    box-shadow: $input-focus-box-shadow;
  }

  &:disabled {
    color: $muted;
    cursor: not-allowed;
    background-color: $cloud;
    border-color: $smoke;
  }

  &:not(:focus):invalid {
    color: $text-muted;
  }
}

// custom file input
input[type="file"] { // stylelint-disable-line selector-no-qualifying-type
  cursor: pointer;
}
input[type="file"]::-webkit-file-upload-button { // stylelint-disable-line selector-no-qualifying-type
  cursor: pointer;
}
.#{$prefix}custom-file {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  margin-bottom: 20px;
  &:hover .#{$prefix}custom-file-label::before {
    border: 1px solid $muted;
  }
  .#{$prefix}form-text {
    margin-top: 4px;
  }
}
.#{$prefix}custom-file ~ .#{$prefix}custom-file-list-wrapper {
  margin-bottom: 12px;
}

.#{$prefix}custom-file-input {
  margin: 0;
}

.#{$prefix}custom-file-label {
  display: flex;
  align-items: flex-end;
  height: 38px;
  padding: $custom-input-padding-y $custom-input-padding-x;
  margin-bottom: 0;
  color: $muted;

  &::after {
    display: none;
  }

  // visualize Choose File button
  &::before {
    position: relative;
    top: 3px;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 2px 8px;
    margin-right: 12px;
    line-height: $custom-input-line-height;
    color: $slate;
    content: "Choose File";
    background-color: $snow;
    border: 1px solid $smoke;
    // stylelint-disable-next-line property-blacklist
    border-radius: 4px;
  }
}

//input text helper for validation, and normal case (form-text)
.valid-feedback,
.invalid-feedback {
  font-size: 12px;
}

.#{$prefix}form-group-label {
  margin-bottom: 0;
  font-weight: 600;
  line-height: $custom-input-line-height;
}

.#{$prefix}custom-control {
  margin-bottom: 4px;
}

.#{$prefix}form-group-label + .#{$prefix}custom-control,
.#{$prefix}form-group-label + .#{$prefix}custom-file {
  margin-top: 4px;
}

//special case for checkbox and radio
//checkboxes & radios are to use .#{$prefix}custom-control-input (NOT .#{$prefix}form-check) to have customize checkbox & radio
.#{$prefix}custom-control-input {
  &:focus {
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }

  &:hover ~ .#{$prefix}custom-control-label::before {
    cursor: pointer;
    border-color: $input-focus-border-color;
  }

  &:hover ~ .#{$prefix}custom-control-label::after,
  &:hover ~ .#{$prefix}custom-control-label {
    cursor: pointer;
  }

  &:disabled ~ .#{$prefix}custom-control-label::after{
    cursor: not-allowed;
    border: none;
  }

  &:disabled ~ .#{$prefix}custom-control-label::before{
    cursor: not-allowed;
    background-color: $cloud !important; // stylelint-disable-line declaration-no-important
    border: 1px solid $smoke !important; // stylelint-disable-line declaration-no-important
  }

  &:disabled ~ .#{$prefix}custom-control-label{
    cursor: not-allowed;
  }
}

.#{$prefix}custom-control-label {
  font-weight: 400;
  color: $dark;
}

.#{$prefix}custom-checkbox {
  .#{$prefix}custom-control-label::before {
    top: 0;
  }
  .#{$prefix}custom-control-label::after {
    top: 0;
    background: no-repeat 50%;
  }
  .#{$prefix}custom-control-input:disabled:checked ~ .#{$prefix}custom-control-label::after {
    background: $custom-checkbox-indicator-icon-checked-inverse no-repeat 50%;
  }
  .#{$prefix}custom-control-input:disabled:indeterminate ~ .#{$prefix}custom-control-label::after {
    background: $custom-checkbox-indicator-icon-indeterminate-inverse no-repeat 50%;
  }
}

.#{$prefix}custom-radio {
  .#{$prefix}custom-control-label::before {
    top: 1px;
  }
  .#{$prefix}custom-control-label::after {
    top: 1px;
    background: no-repeat 100% / 100% 50%;
  }
  .#{$prefix}custom-control-input:disabled:checked ~ .#{$prefix}custom-control-label::after {
    background: $custom-radio-indicator-icon-checked-inverse no-repeat 100% / 100% 50%;
  }
}

.#{$prefix}custom-switch {
  height: 24px;
  padding-left: 52px;
  line-height: 24px;
  .#{$prefix}custom-control-label {
    &::before {
      top: 0;
      left: -52px;
      width: 40px;
      height: 24px;
      background-color: $smoke;
      border: none;
      border-radius: 24px; // stylelint-disable-line property-blacklist
    }
    &::after {
      top: 2px;
      left: -50px;
      width: 20px;
      height: 20px;
      background-color: $white;
      border-radius: 50%; // stylelint-disable-line property-blacklist
      transition: transform .2s; // stylelint-disable-line property-blacklist
    }
  }
  .#{$prefix}custom-control-input:checked ~ .#{$prefix}custom-control-label {
    &::before {
      background-color: $link;
    }
    &::after {
      transform: translateX(1rem);
    }
  }
  @each $color, $value in $theme-colors {
    &.#{$prefix}custom-switch-#{$color} {
      .#{$prefix}custom-control-input:checked ~ .#{$prefix}custom-control-label {
        &::before {
          background: $value;
        }
      }
      .#{$prefix}custom-control-input:not(:disabled):active ~ .#{$prefix}custom-control-label::before {
        background: $value;
      }
      .#{$prefix}custom-control-input:focus ~ .#{$prefix}custom-control-label::before {
        box-shadow: 0 0 0 .2rem lighten($value, 35%);
      }
    }
  }
  &--small {
    height: 20px;
    padding-left: 48px;
    line-height: 20px;
    .#{$prefix}custom-control-label {
      &::before {
        left: -48px;
        width: 34px;
        height: 20px;
      }
      &::after {
        left: -46px;
        width: 16px;
        height: 16px;
      }
    }
    .#{$prefix}custom-control-input:checked ~ .#{$prefix}custom-control-label {
      &::after {
        transform: translateX(14px);
      }
    }
  }
}

// to make sure checkbox & radio have 4px margin bottom
.#{$prefix}form-check {
  margin-bottom: $input-margin-y;
}

// stylelint-disable-next-line selector-no-qualifying-type
textarea.#{$prefix}form-control {
  min-width: 184px;
  height: 80px;
  min-height: 38px;
  max-height: 200px;
  padding-right: 12px;
  padding-left: 12px;
  @include scroll-default;
}

// text helper class in Bootstrap
.#{$prefix}form-text {
  font-size: 12px;
  color: $slate;
}

// input groups classess
.#{$prefix}input-group {
  margin-top: $input-margin-y;
  margin-bottom: $input-margin-y;

  .#{$prefix}form-control {
    margin-top: 0;
    margin-bottom: 0;
  }

  .valid-feedback,
  .invalid-feedback {
    margin-top: 4px;
  }
}

/* stylelint-disable property-no-vendor-prefix */
.#{$prefix}custom-select,
.#{$prefix}custom-select-sm {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* stylelint-enable property-no-vendor-prefix */

.#{$prefix}form-xs {
  width: 80px;
  max-width: 80px;
}

.#{$prefix}form-sm {
  width: 184px;
  max-width: 184px;
}

.#{$prefix}form-md {
  width: 288px;
  max-width: 288px;
}

.#{$prefix}form-lg {
  width: 392px;
  max-width: 392px;
}

.#{$prefix}form-xl {
  width: 600px;
  max-width: 600px;
}

.#{$prefix}counter-label {
  float: right;
  font-weight: $font-weight-normal;
}

.#{$prefix}input-group-text {
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;
  margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  @include font-size($input-font-size); // Match inputs
  font-weight: $font-weight-normal;
  line-height: $input-line-height;
  color: $input-group-addon-color;
  text-align: center;
  white-space: nowrap;
  background-color: $input-group-addon-bg;
  border: $input-border-width solid $input-group-addon-border-color;
  @include border-radius($input-border-radius);
}

.input-date-picker, // will be deprecated in future version
.input-no-readonly {
  color: $dark !important; // stylelint-disable-line declaration-no-important
  cursor: pointer !important; // stylelint-disable-line declaration-no-important
  background-color: $white !important; // stylelint-disable-line declaration-no-important
  &:hover:not(:disabled) {
    border: 1px solid $input-focus-border-color;
  }
}

.input-with-icon-container { // will be deprecated in future version
  position: relative;
}

// stylelint-disable property-no-vendor-prefix
// stylelint-disable selector-no-qualifying-type
input[type="date"] {
  -webkit-appearance: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
// stylelint-enable property-no-vendor-prefix
// stylelint-enable selector-no-qualifying-type
