@import "../mixins/text";

$heading-size: 24px, 20px, 16px;
@for $i from 1 through length($heading-size) {
  h#{$i},
  .h#{$i} {
    margin-bottom: 0;
    font-size: nth($heading-size, $i);
    line-height: nth($heading-size, $i) + 8;
    color: $dark;
    letter-spacing: $letter-spacing;
  }
}

body,
p,
.font-size-default {
  margin-bottom: 0;
  @extend %font-size-base;
}

small,
.small {
  @extend %font-size-sm;
  color: $slate;
}

:disabled,
[readonly] {
  color: $text-muted;
}

.text-base {
  font-size: $font-size-base;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-sm {
  font-size: $font-size-sm;
}

.large-title {
  font-size: 32px;
  font-weight: $font-weight-bold;
  line-height: 48px;
  letter-spacing: $letter-spacing;
}
