// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               1rem;
$alert-border-radius:               4px;
$alert-link-font-weight:            500;
$alert-border-width:                1px;

$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 6;
