$breadcrumb-bg:             transparent;
$breadcrumb-margin-bottom:  0;
$breadcrumb-padding-y:      0;
$breadcrumb-padding-x:      0;
$breadcrumb-divider-color:  $slate;
$breadcrumb-item-padding:   4px;
$breadcrumb-border-radius:  4px;
$breadcrumb-divider: quote("/");
$breadcrumb-divider-color: $slate;
$breadcrumb-active-color: $dark;
