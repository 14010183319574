.date-picker-container {
  position: absolute;
  z-index: 11;
  width: 392px;
  height: auto;
}

%date-picker-calendar {
  position: absolute;
  width: 392px;
  padding: 0 16px;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid $smoke;
  border-radius: 4px; // stylelint-disable-line property-blacklist
  box-shadow: 0 2px 4px 0 rgba($slate, .1), 0 4px 8px 0 rgba($slate, .2);
}

.date-picker-calendar-view {
  @extend %date-picker-calendar;
  height: auto;
  min-height: 284px;
}

.date-picker-collapse-view {
  @extend %date-picker-calendar;
  height: auto;
  min-height: 244px;
}

.date-picker-header {
  display: flex;
  align-items: center;
  height: 52px;
  text-align: center;
}

.date-picker-header-title {
  flex-grow: 5;
  font-weight: $font-weight-bold;
  cursor: pointer;
}

.date-picker-previous-button {
  flex-grow: 1;
  padding-right: 16px;
  text-align: left;
  .ic {
    &:hover {
      cursor: pointer;
    }
  }
}

.date-picker-next-button {
  flex-grow: 1;
  padding-left: 16px;
  text-align: right;
  .ic {
    &:hover {
      cursor: pointer;
    }
  }
}

%cell-current-state {
  border-radius: 100%; // stylelint-disable-line property-blacklist
  -webkit-box-shadow: inset 0 0 0 1px $primary; // stylelint-disable-line property-no-vendor-prefix
  -moz-box-shadow: inset 0 0 0 1px $primary; // stylelint-disable-line property-no-vendor-prefix
  box-shadow: inset 0 0 0 1px $primary;
}

%cell-selected-state {
  color: $white !important; // stylelint-disable-line declaration-no-important
  background-color: $primary !important; // stylelint-disable-line declaration-no-important
  border-radius: 100%; // stylelint-disable-line property-blacklist
}

%cell-disabled-state {
  color: $muted !important; // stylelint-disable-line declaration-no-important
  background-color: transparent !important; // stylelint-disable-line declaration-no-important
  border-radius: 100%; // stylelint-disable-line property-blacklist

  &:hover {
    cursor: default;
  }
}

%cell-hover {
  color: $dark;
  cursor: pointer;
  background-color: $cloud;
  border-radius: 100%; // stylelint-disable-line property-blacklist
  -webkit-box-shadow: none; // stylelint-disable-line property-no-vendor-prefix
  -moz-box-shadow: none; // stylelint-disable-line property-no-vendor-prefix
  box-shadow: none; // stylelint-disable-line property-no-vendor-prefix
}

.date-picker-row {
  display: flex;
  flex-wrap: wrap;
  .date-picker-cell {
    flex: 1 1 0;
    margin: 2px 0;
    text-align: center;
    .date-picker-date-cell {
      width: 32px;
      height: 32px;
      margin: 0 auto;
      line-height: 32px;
      &.current-date {
        @extend %cell-current-state;
      }
      &.selected-date {
        @extend %cell-selected-state;
      }
      &.disabled-date {
        @extend %cell-disabled-state;
      }
      &:hover {
        @extend %cell-hover;
      }
    }
    .date-picker-month-cell {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 12px;
      line-height: 40px;
      &.current-month {
        @extend %cell-current-state;
      }
      &.selected-month {
        @extend %cell-selected-state;
      }
      &.disabled-month {
        @extend %cell-disabled-state;
      }
      &:hover {
        @extend %cell-hover;
      }
    }
    .date-picker-year-cell {
      width: 48px;
      height: 48px;
      margin: 0 auto;
      margin-bottom: 4px;
      line-height: 48px;
      &.current-year {
        @extend %cell-current-state;
      }
      &.selected-year {
        @extend %cell-selected-state;
      }
      &.disabled-year {
        @extend %cell-disabled-state;
      }
      &:hover {
        @extend %cell-hover;
      }
    }
    &:hover {
      .date-picker-date-cell {
        @extend %cell-hover;
      }
    }
  }
}

.date-picker-day-container {
  display: flex;
  margin-bottom: 16px;
  .date-picker-day {
    flex: 1 1 0;
    color: $slate;
    text-align: center;
  }
}

.date-picker-shortcut-button {
  width: 360px;
  padding: 16px 0;
  color: $slate;
  text-align: center;
  cursor: pointer;
  &.disabled {
    color: $muted;
    cursor: not-allowed;
  }
}

.date-picker-break {
  flex-basis: 100%;
  height: 0;
}

.date-picker--no-outline {
  outline: none;
}
