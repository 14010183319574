@each $color, $hex in $theme-colors {
  @for $i from 1 through 4 {
    .border-#{$i}-#{$color} {
      // stylelint-disable-next-line declaration-no-important
      border: #{$i}px solid $hex !important;
    }
    .border-top-#{$i}-#{$color} {
      // stylelint-disable-next-line declaration-no-important
      border-top: #{$i}px solid $hex !important;
    }
    .border-right-#{$i}-#{$color} {
      // stylelint-disable-next-line declaration-no-important
      border-right: #{$i}px solid $hex !important;
    }
    .border-bottom-#{$i}-#{$color} {
      // stylelint-disable-next-line declaration-no-important
      border-bottom: #{$i}px solid $hex !important;
    }
    .border-left-#{$i}-#{$color} {
      // stylelint-disable-next-line declaration-no-important
      border-left: #{$i}px solid $hex !important;
    }
  }
}
