// Spacing for Mekari UI
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25), // tiny
  2: ($spacer * .5), // xmall
  3: $spacer, // small
  4: ($spacer * 1.5), // normal
  5: ($spacer * 2), // medium
  6: ($spacer * 2.5), // large
  7: ($spacer * 5) // xlarge
);

$headings-margin-bottom: $spacer / 2;
$paragraph-margin-bottom: $spacer;
$label-margin-bottom: 8px;

//pagination

$pagination-padding-y:              8px;
$pagination-padding-x:              12px;
$pagination-padding-y-sm:           4px;
$pagination-padding-x-sm:           8px;
$pagination-padding-y-lg:           12px;
$pagination-padding-x-lg:           24px;
$pagination-line-height:            20px;

$pagination-border-width: 1px;
$pagination-border-color: $smoke;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $smoke;
$pagination-hover-border-color: $muted;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-focus-outline: 0;
$pagination-focus-box-shadow: 0 0 0 .2rem $primary;

$pagination-active-color:           $white;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $slate;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $smoke;

$line-height-lg: 32px;

//images

$thumbnail-padding: 4px;
$thumbnail-bg: $body-bg;

$thumbnail-border-width: 1px;
$thumbnail-border-color: $smoke;
$thumbnail-border-radius: 4px;
$thumbnail-box-shadow: 0 1px 2px rgba($black, .075);
$figure-caption-font-size: 90%;
$figure-caption-color: $slate;
