@keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.shimmer {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #e2e2e2), color-stop(18%, #b4b4b4), color-stop(33%, #e2e2e2));
  background: linear-gradient(to right, #e2e2e2 2%, #b4b4b4 35%, #e2e2e2 40%);
  background-size: 2800px 104px;
  animation: shimmer 1s linear infinite;
  animation-fill-mode: forwards;
}
