$modal-content-border-color:  $smoke;
$modal-title-line-height:     20px;
$modal-header-padding:        17px 24px 15px;
$modal-inner-padding:         24px;
$modal-backdrop-opacity:      .75;

$modal-lg:                    808px;
$modal-md:                    600px;
$modal-sm:                    392px;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

$modal-dialog-margin:               .5rem;
$modal-dialog-margin-y-sm-up:       1.75rem;
$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-width: 1px;
$modal-content-border-radius: .3rem;

$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:                 $black;
$modal-header-border-width:         $modal-content-border-width;
$modal-header-border-color:         $smoke;
$modal-header-padding-y:            16px;
$modal-header-padding-x:            16px;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-footer-border-width: 1px;
$modal-footer-border-color: $smoke;
$modal-xl:                          1140px;

$close-font-size: $font-size-base * 1.5;
$close-font-weight:                 $font-weight-bold;
$close-color: $slate;
$close-text-shadow:                 0 1px 0 $white;
