.#{$prefix}table {
  margin-bottom: 0;
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid $smoke;
  @include border-radius(4px);
  td {
    background: $white;
  }
  tr:hover {
    background: $cloud;
    td {
      background: inherit;
    }
  }
  th,
  td {
    padding: 10px 16px;
    border-top: 0;

    .#{$prefix}btn-icon {
      margin-top: -8px;
      margin-bottom: -8px;
    }
  }
  thead {
    th {
      background-color: $light;
      border-bottom: $table-border-width solid $table-border-color;
      &:first-child {
        // stylelint-disable-next-line property-blacklist
        border-top-left-radius: 4px;
      }
      &:last-child {
        // stylelint-disable-next-line property-blacklist
        border-top-right-radius: 4px;
      }
    }
    .sort {
      cursor: pointer;
      span {
        position: relative;
        &::before,
        &::after {
          position: absolute;
          right: -12px;
          width: 0;
          height: 0;
          content: "";
          border-style: solid;
        }
        &::before {
          top: 2px;
          border-color: transparent transparent $slate transparent;
          border-width: 0 4px 4px;
        }
        &::after {
          bottom: 2px;
          border-color: $slate transparent transparent transparent;
          border-width: 4px 4px 0;
        }
      }
      &.sort-up,
      &.sort-down {
        span {
          &::before {
            border-color: transparent transparent $dark transparent;
          }
          &::after {
            border-color: $dark transparent transparent transparent;
          }
        }
      }
    }
  }
  tbody {
    tr:not(:last-child) {
      // stylelint-disable-next-line selector-max-type
      th,
      td {
        border-bottom: 1px solid $table-border-color;
      }
    }
  }

  .#{$prefix}table-action-list {
    position: sticky;
    left: 16px;
  }

  &.#{$prefix}table-bordered {
    tbody {
      th,
      td {
        border-bottom: 0;
      }
    }
    th,
    td {
      border-right: $table-border-width solid $table-border-color;
      border-left: 0;
    }
    thead th:last-child,
    tbody td:last-child {
      border-right: 0;
    }
  }

  .#{$prefix}table {
    &__checkbox {
      width: 32px;
      padding-right: 0;

      .#{$prefix}custom-control {
        padding-left: 16px;
        margin-bottom: 0;

        &-label {
          &::before,
          &::after {
            left: -16px;
          }
        }
      }
    }
  }
}

.#{$prefix}table-layout-auto {
  table-layout: auto !important; // stylelint-disable-line declaration-no-important
}

.#{$prefix}table-sticky {
  thead tr th {
    position: sticky;
    top: 0;
  }
  th:nth-child(1),
  th:last-child {
    z-index: 2;
  }
  &--left {
    td:nth-child(1),
    th:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }
  &--right {
    td:last-child,
    th:last-child {
      position: sticky;
      right: 0;
      z-index: 2;
    }
  }
}
