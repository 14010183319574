.#{$prefix}modal {
  .#{$prefix}modal-header {
    position: relative;
    padding: 16px 16px 16px 24px;

    .close {
      padding: 16px;
      margin: -16px -16px -16px auto;
      font-size: 20px;
      font-weight: 600;
      opacity: 1;

      span {
        color: $slate;
      }
    }
  }
  .#{$prefix}modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .#{$prefix}modal-body {
    padding: 16px 24px;
  }
  .#{$prefix}modal-footer {
    padding: 4px 24px 32px;
    border-top: 0;
  }
}

.#{$prefix}modal-backdrop {
  opacity: $modal-backdrop-opacity;
}
