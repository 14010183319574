$dropdown-link-disabled-color: $muted;
$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;


$dropdown-min-width:                10rem;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                .875rem;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white;
$dropdown-border-color:             rgba($black, .15);
$dropdown-border-radius:            4px;
$dropdown-border-width:             1px;
$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
$dropdown-divider-bg:               $gray-200;
$dropdown-divider-margin-y:         8px;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175);

$dropdown-link-color:               $gray-900;
$dropdown-link-hover-color:         darken($gray-900, 5%);
$dropdown-link-hover-bg:            $gray-100;

$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $primary;

$dropdown-link-disabled-color:      $muted;

$dropdown-item-padding-y:           .25rem;
$dropdown-item-padding-x:           1.5rem;

$dropdown-header-color:             $gray-600;
