.#{$prefix}tooltip {
  margin: 0;
  opacity: .9;

  @extend %font-size-sm;

  &-inner {
    text-align: left;
    box-shadow: 0 2px 4px 0 rgba(65, 64, 66, .2);
  }
}
