@import "../../mixins/text";

.#{$prefix}dropdown {
  &-toggle {
    &::after {
      display: none;
      vertical-align: 2px;
    }
  }

  &-menu {
    width: auto;
    min-width: 80px;
    max-width: 684px;
    padding: 0;
    margin: 4px 0 0;
    overflow-y: hidden;
    background-color: $white;
    border: 1px solid $smoke;
    box-shadow: 0 2px 4px rgba(119, 119, 119, .1), 0 4px 8px rgba(119, 119, 119, .2);
    @include border-radius(4px);
    @include scroll-default;
    &--small {
      width: 80px;
    }
    &--medium {
      width: 184px;
    }
    &--large {
      width: 288px;
    }
    &--max-content {
      width: max-content;
    }
    &--fixed-height {
      max-height: 180px;
      .#{$prefix}dropdown-item--container {
        max-height: 180px;
        overflow: auto;
      }
      .#{$prefix}dropdown-item--search + .#{$prefix}dropdown-item--container {
        max-height: 126px;
      }
    }
  }

  &-item {
    padding: 8px;
    color: $dark;
    white-space: normal;
    cursor: pointer;
    outline: none;

    &--title {
      @extend %font-size-sm;
      padding-top: 12px;
      padding-bottom: 4px;
      color: $slate;
      text-transform: capitalize;
      border-top: 1px solid $smoke;
    }

    &--title:first-child {
      border: none;
    }

    &:hover {
      background-color: $cloud;
      &:active {
        color: $dark;
      }
    }

    &.active {
      &:hover {
        background-color: darken($primary, 10%);
        &:active {
          color: $cloud;
        }
      }
      .ic {
        color: inherit;
      }
    }

    &.is-selected {
      background-color: $cloud;
    }
  }

  &-divider {
    margin: 0;
  }

  &-not-found {
    display: block;
    padding: 8px;
    color: $muted;
    text-align: center;
  }

  &-selected-list {
    margin-top: 4px;

    .#{$prefix}badge {
      margin-top: 4px;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-action {
    padding: 8px;

    input {
      width: 100%;
      margin: 0;
    }

    &:hover {
      background-color: inherit;
    }
  }

  &-with-action {
    .#{$prefix}dropdown-menu {
      max-height: none;
      overflow: hidden;

      .#{$prefix}dropdown-action {
        &:hover {
          background-color: inherit;
        }
      }

      &-list {
        height: auto;
        max-height: 126px;
        overflow-y: auto;
        @include scroll-default;
      }
    }
  }

  &-with-avatar {
    span {
      vertical-align: middle;
    }
  }

  &-with-pill {
    .#{$prefix}dropdown-menu-list {
      .#{$prefix}dropdown-item {
        &.is-selected {
          color: $muted;
          pointer-events: none;
          background-color: transparent;
        }
      }
    }
  }
  &--medium {
    width: 184px;
  }
  &--large {
    width: 288px;
  }
}
