.#{$prefix}breadcrumb {
  li {
    a {
      color: $slate;
    }
  }
}

.#{$prefix}breadcrumb-item.active {
  a {
    color: $breadcrumb-active-color;
    text-decoration: underline;
  }
}
