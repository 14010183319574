// Neutrals color variables
$dark:  #212121;
$slate: #777;
$muted: #b4b4b4;
$smoke: #e2e2e2;
$snow:  #fbfbfb;
$white: #fff;
$cloud: #f2f4f7;

// UI color variables
$red:           #d74022;
$green:         #009f61;
$light-yellow:  #ffeb99;
$blue:          #1357ff;
$orange:        #ffb700;
$sapphire:      #005fbf;

// Branding color variables
$crimson:     #c02a34;
$azure:       #009bde;
$salamander:  #f96d01;
$purple:      #6d48e5;

// Bootstrap dependencies color
$black:    #000 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;


$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

// register mekari color variables too bootstrap
$colors: ();
$colors: map-merge(
  (
    "noir"         : $dark, // going to deprecated
    "dark"         : $dark,
    "slate"        : $slate,
    "muted"        : $muted,
    "smoke"        : $smoke,
    "snow"         : $snow,
    "cloud"        : $cloud,
    "crimson"      : $crimson,
    "azure"        : $azure,
    "salamander"   : $salamander,
    "purple"       : $purple,
    "sapphire"     : $sapphire,
    "white"        : $white,
    "red"          : $red,
    "orange"       : $orange,
    "green"        : $green,
    "blue"         : $blue,
    "light-yellow" : $light-yellow
  ),
  $colors
);

//extend bootstrap class
$danger:    $red;
$success:   $green;
$info:      $light-yellow;
$warning:   $orange;
$primary:   $sapphire;
$jurnal:    $azure;
$talenta:   $crimson;
$klikpajak: $salamander;
$mekari:    $purple;

$link: $blue;

$text-muted:  $muted;
$body-color:  $dark;
$dark:        $dark;
$secondary:   $slate;
$light:       $snow;

// Add more theme color variables
$theme-colors: ();
$theme-colors: map-merge(
  (
    "danger"    : $danger,
    "success"   : $success,
    "info"      : $info,
    "warning"   : $warning,
    "primary"   : $primary,
    "jurnal"    : $jurnal,
    "talenta"   : $talenta,
    "klikpajak" : $klikpajak,
    "mekari"    : $mekari,
    "dark"      : $dark,
    "slate"     : $slate,
    "muted"     : $muted,
    "smoke"     : $smoke,
    "light"     : $light,
    "cloud"     : $cloud,
    "link"      : $blue,
    "white"     : $white
  ),
  $theme-colors
);

$card-border-color: $smoke;
$body-bg: $white;
