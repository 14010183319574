// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($blue, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      1px;

// Forms

$label-margin-bottom:                   .5rem;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     $cloud;

$input-color:                           $dark;
$input-border-color:                    $smoke;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-border-radius:                   4px;
$input-border-radius-lg:                4px;
$input-border-radius-sm:                4px;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              lighten($primary, 25%);
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               $gray-600;
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y});
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{$input-padding-y / 2});

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm:                       calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.25rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-grid-gutter-width:                10px;
$form-group-margin-bottom:              1rem;

$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  $cloud;
$input-group-addon-border-color:        $input-border-color;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter:                 .5rem;
$custom-control-spacer-x:               1rem;

$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray-600;

$custom-control-indicator-checked-color:        $white;
$custom-control-indicator-checked-bg:           $primary;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5);
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $white;
$custom-control-indicator-active-bg:            lighten($primary, 35%);
$custom-control-indicator-active-box-shadow:    none;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius:       4px;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml, %3csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.542893 4.54289C0.933418 4.15237 1.56658 4.15237 1.95711 4.54289L4.25 6.83579L10.0429 1.04289C10.4334 0.652369 11.0666 0.652369 11.4571 1.04289C11.8476 1.43342 11.8476 2.06658 11.4571 2.45711L4.95711 8.95711C4.56658 9.34763 3.93342 9.34763 3.54289 8.95711L0.542893 5.95711C0.152369 5.56658 0.152369 4.93342 0.542893 4.54289Z' fill='%23ffffff'/%3e%3c/svg%3e");
$custom-checkbox-indicator-icon-checked-inverse:        url("data:image/svg+xml, %3csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.542893 4.54289C0.933418 4.15237 1.56658 4.15237 1.95711 4.54289L4.25 6.83579L10.0429 1.04289C10.4334 0.652369 11.0666 0.652369 11.4571 1.04289C11.8476 1.43342 11.8476 2.06658 11.4571 2.45711L4.95711 8.95711C4.56658 9.34763 3.93342 9.34763 3.54289 8.95711L0.542893 5.95711C0.152369 5.56658 0.152369 4.93342 0.542893 4.54289Z' fill='%23b4b4b4'/%3e%3c/svg%3e");

$custom-checkbox-indicator-indeterminate-bg:           $primary;
$custom-checkbox-indicator-indeterminate-color:        $white;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -1 6 6'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");

$custom-checkbox-indicator-icon-indeterminate-inverse:         url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 -1 6 6'%3e%3cpath stroke='%23b4b4b4' d='M0 2h4'/%3e%3c/svg%3e");

$custom-checkbox-indicator-indeterminate-box-shadow:   none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-control-indicator-checked-bg;

$custom-radio-indicator-border-radius:          50%;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");

$custom-radio-indicator-icon-checked-inverse:           url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b4b4b4'/%3e%3c/svg%3e");

$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2;
$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4});

$custom-select-padding-y:           $input-padding-y;
$custom-select-padding-x:           $input-padding-x;
$custom-select-font-family:         $input-font-family;
$custom-select-font-size:           $input-font-size;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight;
$custom-select-line-height:         $input-line-height;
$custom-select-color:               $slate;
$custom-select-disabled-color:      $muted;
$custom-select-bg:                  $input-bg;
$custom-select-disabled-bg:         $cloud;
$custom-select-bg-size:             24px; // In pixels because image dimensions
// for arrow down icon in Mekari's select
$custom-select-indicator-color:    $slate;
$custom-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='%23777' d='M6.28178611,7.34112994 C5.98969924,7.04743259 5.5148273,7.04612703 5.22112994,7.33821389 C4.92743259,7.63030076 4.92612703,8.1051727 5.21821389,8.39887006 L9.44490899,12.6488701 C9.73699896,12.9425705 10.2118771,12.9438725 10.5055731,12.6517782 L14.7788781,8.40177816 C15.072571,8.10968687 15.0738694,7.63481492 14.7817782,7.34112195 C14.4896869,7.04742898 14.0148149,7.04613056 13.7211219,7.33822184 L9.9796032,11.0593358 L6.28178611,7.34112994 Z'/%3e%3c/svg%3e");

$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple
$custom-select-feedback-icon-padding-right: calc((1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding});
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half;

$custom-select-border-width:        $input-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       4px;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075);

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-width:         $input-focus-width;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color;

$custom-select-padding-y-sm:        $input-padding-y-sm;
$custom-select-padding-x-sm:        $input-padding-x-sm;
$custom-select-font-size-sm:        $input-font-size-sm;
$custom-select-height-sm:           $input-height-sm;

$custom-select-padding-y-lg:        $input-padding-y-lg;
$custom-select-padding-x-lg:        $input-padding-x-lg;
$custom-select-font-size-lg:        $input-font-size-lg;
$custom-select-height-lg:           $input-height-lg;

$custom-range-track-width:          100%;
$custom-range-track-height:         .5rem;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             $gray-300;
$custom-range-track-border-radius:  1rem;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width:                   1rem;
$custom-range-thumb-height:                  $custom-range-thumb-width;
$custom-range-thumb-bg:                      $primary;
$custom-range-thumb-border:                  0;
$custom-range-thumb-border-radius:           1rem;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($primary, 35%);
$custom-range-thumb-disabled-bg:             $gray-500;

$custom-file-height:                $input-height;
$custom-file-height-inner:          $input-height-inner;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      $input-focus-box-shadow;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             $input-padding-y;
$custom-file-padding-x:             $input-padding-x;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $input-font-weight;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            $input-box-shadow;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $input-group-addon-bg;
$custom-file-text: (
  en: "Browse"
);


// Form validation

$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $font-size-sm;
$form-feedback-valid-color:         $green;
$form-feedback-invalid-color:       $red;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23");

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     .25rem;
$form-feedback-tooltip-padding-x:     .5rem;
$form-feedback-tooltip-font-size:     $font-size-sm;
$form-feedback-tooltip-line-height:   $line-height-base;
$form-feedback-tooltip-opacity:       .9;
$form-feedback-tooltip-border-radius: 4px;



// Custom from mekari

$input-height: 38px;
$input-placeholder-color: $muted;
$form-feedback-margin-top: 0;
$input-border-radius: 4px;

$input-focus-border-color: $sapphire;
$input-focus-box-shadow: 0 0 4px 1px rgba($sapphire, .3);

//different focus shadow for radio and checkbox
$custom-control-label-disabled-color: $muted;
$custom-select-padding-y: 8px;

// text helper
$form-text-margin-top:  0;

// form-group
$form-group-margin-bottom: 20px;


// input form button text
$custom-file-text: (
  en: "Choose file",
  id: "Pilih file",
);

// remove validation icons
$enable-validation-icons: false;
