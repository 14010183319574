// variables for icons
$icons-map: ();
$icons-map: map-merge(
  (
    "ic-activity": \e93a,
    "ic-add": \e800,
    "ic-addons": \e93b,
    "ic-add-fill": \e95e,
    "ic-add-fill-light": \e992,
    "ic-add-staff": \e956,
    "ic-adjustment": \e947,
    "ic-alarm": \e93c,
    "ic-approval": \e948,
    "ic-arrow-down": \e94b,
    "ic-arrow-left": \e94c,
    "ic-arrow-right": \e94d,
    "ic-arrow-up": \e94e,
    "ic-article-fill": \e96c,
    "ic-asset": \e949,
    "ic-attachment": \e88e,
    "ic-bank": \e94a,
    "ic-bank-fill": \e96d,
    "ic-billing": \e934,
    "ic-billing-fill": \e960,
    "ic-blank-icon": \e93d,
    "ic-branch": \e935,
    "ic-broadcast": \e936,
    "ic-camera": \e89e,
    "ic-camera-fill": \e877,
    "ic-calculator": \e84c,
    "ic-calendar": \e938,
    "ic-cart-fill": \e96e,
    "ic-celebration": \e939,
    "ic-certificate": \e8a1,
    "ic-chat": \e92e,
    "ic-chart-horizontal": \e979,
    "ic-chart-vertical": \e97a,
    "ic-chevron-first": \e942,
    "ic-chevron-last": \e943,
    "ic-chevron-bottom": \e88b,
    "ic-chevron-left": \e944,
    "ic-chevron-right": \e945,
    "ic-chevron-up": \e88c,
    "ic-coa": \e92f,
    "ic-copy": \e88a,
    "ic-collapse": \e89b,
    "ic-column-settings": \e89c,
    "ic-column-settings-small": \e801,
    "ic-company": \e930,
    "ic-contact": \e931,
    "ic-credit-card": \e932,
    "ic-custom-slip": \e933,
    "ic-dashboard": \e928,
    "ic-delete": \e900,
    "ic-description": \e929,
    "ic-doc": \e92a,
    "ic-document-pdf": \e959,
    "ic-dot": \e8f0,
    "ic-drag": \e88d,
    "ic-dropdown": \e8ef,
    "ic-edit": \e887,
    "ic-education": \e8a0,
    "ic-education-fill": \e970,
    "ic-equal": \e886,
    "ic-error": \e93e,
    "ic-error-fill": \e89a,
    "ic-event": \e958,
    "ic-expand": \e899,
    "ic-expense": \e92b,
    "ic-expense-fill": \e971,
    "ic-external-link": \e963,
    "ic-family": \e89f,
    "ic-favorite": \e977,
    "ic-favorite-fill": \e978,
    "ic-feature": \e972,
    "ic-feedback-fill": \e973,
    "ic-file-comment": \e953,
    "ic-file-history": \e952,
    "ic-finance": \e92c,
    "ic-finance-dollar": \e883,
    "ic-finance-percentage": \e881,
    "ic-finance-rupiah": \e882,
    "ic-file-download": \e885,
    "ic-file-upload": \e884,
    "ic-filter": \e993,
    "ic-fit-screen": \e97b,
    "ic-fnb": \e974,
    "ic-help": \e92d,
    "ic-help-small": \e880,
    "ic-history": \e922,
    "ic-home": \e923,
    "ic-img": \e924,
    "ic-inbox": \e925,
    "ic-inbox-fill": \e902,
    "ic-info": \e898,
    "ic-info-fill": \e903,
    "ic-insurance": \e926,
    "ic-integrasi": \e927,
    "ic-job-level": \e91c,
    "ic-job-title": \e91d,
    "ic-jurnal": \e84b,
    "ic-klikpajak": \e950,
    "ic-lightbulb": \e961,
    "ic-list-fill": \e975,
    "ic-loading": \e896,
    "ic-loan": \e94f,
    "ic-location": \e91e,
    "ic-lock": \e91f,
    "ic-logout": \e920,
    "ic-kebab": \e897,
    "ic-manufacture": \e976,
    "ic-meatball": \e895,
    "ic-message": \e957,
    "ic-merge": \e87f,
    "ic-migrate-fill": \e964,
    "ic-migration": \e921,
    "ic-m-download": \e95d,
    "ic-m-progress": \e95c,
    "ic-m-remove": \e95b,
    "ic-m-success": \e95a,
    "ic-notification": \e916,
    "ic-offboarding": \e917,
    "ic-onboarding": \e918,
    "ic-organization": \e919,
    "ic-other": \e91b,
    "ic-other-fill": \e965,
    "ic-other-list": \e91a,
    "ic-outline-history": \e962,
    "ic-overtime": \e910,
    "ic-on-process-fill": \e95f,
    "ic-picker-calendar": \e87e,
    "ic-picker-time": \e87d,
    "ic-plans": \e955,
    "ic-product": \e911,
    "ic-product-fill": \e966,
    "ic-profile-pic": \e912,
    "ic-purchase": \e913,
    "ic-reload": \e914,
    "ic-report": \e915,
    "ic-report-fill": \e967,
    "ic-remove-line": \e87c,
    "ic-remove-line-fill": \e991,
    "ic-reset": \e90a,
    "ic-reset-fill": \e87a,
    "ic-resync": \e8f2,
    "ic-retail": \e968,
    "ic-rules": \e90b,
    "ic-sale": \e90c,
    "ic-sale-fill": \e96f,
    "ic-search": \e893,
    "ic-send": \e892,
    "ic-services": \e969,
    "ic-setting": \e90d,
    "ic-setting-fill": \e96a,
    "ic-sort": \e879,
    "ic-staff": \e90e,
    "ic-stepper-error": \e901,
    "ic-stepper-finished": \e909,
    "ic-success": \e93f,
    "ic-success-fill": \e891,
    "ic-supplier": \e90f,
    "ic-sync": \e8f1,
    "ic-tag": \e878,
    "ic-talenta": \e951,
    "ic-task": \e946,
    "ic-tax-history": \e905,
    "ic-team-goal": \e954,
    "ic-time": \e906,
    "ic-transfer-account": \e907,
    "ic-trash": \e900,
    "ic-tour-fill": \e96b,
    "ic-upload-file": \e908,
    "ic-view-fill": \e890,
    "ic-view-hide-fill": \e88f,
    "ic-warning-fill": \e940,
    "ic-warning": \e941,
    "ic-zip": \e904,
  ),
  $icons-map
);

$icon-blankslate-path: "#{$icon-font-path}blankslate";
$icons-blankslate-name: "calendar", "location", "not-found", "process", "default";
$icons-blankslate-product: "jurnal", "klikpajak", "talenta";

// To properly convert Unicodes in the resulting CSS
@function icon-sanitize($icon) {
  @return unquote("\"#{ $icon }\"");
}

%ic-template {
  width: 24px;
  height: 24px;
  font-family: "#{$icon-font-family-prefix}#{$icon-font-family}", sans-serif;
  font-size: 24px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.ic {
  display: inline-block;
  @extend %ic-template;
  &.ic-small {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
  &.ic-medium {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
  &.ic-large {
    width: 60px;
    height: 60px;
    font-size: 60px;
  }
  &.ic-blankslate {
    width: 120px;
    height: 120px;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px 100px;
  }
}

.ic-input-prefix {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 5;
  padding: 0;
  color: inherit;
  pointer-events: all;
  cursor: pointer;
  &.ic-small {
    top: 8px;
    left: 10px;
  }
}

.ic-input-suffix {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 5;
  padding: 0;
  color: inherit;
  pointer-events: all;
  cursor: pointer;
  &.ic-input-double-suffix, // this will be deprecated in future version
  &.ic-input-suffix--double {
    right: 36px;
    z-index: 5;
  }
  &.ic-small {
    top: 8px;
    right: 10px;
    &.ic-input-double-suffix, // this will be deprecated in future version
    &.ic-input-suffix--double {
      right: 38px;
    }
  }
}

@each $name, $value in $icons-map {
  .#{$name} {
    color: $slate;
    &::before {
      color: inherit;
      content: icon-sanitize($value);
    }
  }
}

@each $product in $icons-blankslate-product {
  @each $name in $icons-blankslate-name {
    .ic-blankslate-#{$product}-#{$name} {
      background-image: url("#{$icon-blankslate-path}/#{$product}/#{$name}.svg");
    }
  }
}
