$tooltip-bg:            $dark;
$tooltip-color: $white;
$tooltip-arrow-width:   0;
$tooltip-arrow-height:  .5rem;
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1040;
$zindex-modal:                      1050;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;
$tooltip-font-size: $font-size-sm;
$tooltip-opacity: .9;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-max-width: 200px;
$tooltip-padding-y:                 .25rem;
$tooltip-padding-x:                 .5rem;
$tooltip-margin:        .5rem;
$tooltip-border-radius: 4px;
