%avatar-default-template {
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: 60% 60%;
}

%avatar-overlay-initial-state {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

$avatar-element-size: (
  "xsmall": 24px,
  "small": 32px,
  "medium": 40px,
  "large": 60px,
  "xlarge": 80px,
  "xxlarge": 120px,
);

.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  background-color: $smoke;
  &.dropdown-toggle {
    &::after {
      display: initial !important; // stylelint-disable-line declaration-no-important
    }
  }
  &::after {
    content: "\A";
    @extend %avatar-overlay-initial-state;
    background: rgba(0, 0, 0, .5) !important; // stylelint-disable-line declaration-no-important
  }
  &:hover {
    cursor: pointer;
    &::after {
      cursor: pointer;
      opacity: 1;
    }
  }
  .avatar-photo {
    width: 100%;
    height: 100%;
    vertical-align: top;
    background-color: $smoke;
  }
  &.avatar-default-people {
    background-image: $avatar-default-people;
    @extend %avatar-default-template;
    background-size: 100% 100%;
  }
  &.avatar-default-file {
    background-image: $avatar-default-file;
    @extend %avatar-default-template;
  }
  &.avatar-default-image {
    background-image: $avatar-default-image;
    @extend %avatar-default-template;
  }
  @each $name, $size in $avatar-element-size {
    &.avatar-#{$name} {
      width: $size;
      height: $size;
      line-height: $size;
      .avatar-initial {
        font-family: $font-family-base;
        font-size: $size * .375;
        color: $slate;
      }
    }
  }
  $avatar-font-family: "Mekari-Icon-Regular";
  &.avatar--overlay-icon {
    font-family: $avatar-font-family;
    font-size: 24px;
    &::after {
      color: $white;
      content: "\e877";
      @extend %avatar-overlay-initial-state;
    }
    &:hover {
      &::after {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  &.avatar-circle {
    border-radius: 100%; // stylelint-disable-line property-blacklist
  }
  &.avatar-square {
    border-radius: 8px; // stylelint-disable-line property-blacklist
  }
  &.avatar--no-overlay {
    &:hover::after {
      opacity: 0;
    }
  }
}

.avatar-stacked {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
  @each $name, $size in $avatar-element-size {
    .avatar-#{$name} {
      box-shadow: #{$size / 40} 0 0 #{$size / 40} $white;
    }
    @for $i from 1 through 6 {
      .avatar-#{$name}:nth-child(#{$i}) {
        @if $i == 1 {
          margin-left: 0;
        } @else {
          margin-left: -#{$size / 4};
        }
        z-index: 7 - $i;
      }
    }
  }
  .avatar-stacked-text {
    position: relative;
    padding-left: 16px;
    color: $link;
    cursor: pointer;
    p {
      margin: 0;
      line-height: 14px;
    }
    &.dropdown-toggle::after {
      display: none;
    }
  }
  &.avatar-stacked-reversed {
    @each $name, $size in $avatar-element-size {
      .avatar-#{$name} {
        box-shadow: -#{$size / 40} 0 0 #{$size / 40} $white;
      }
      @for $i from 1 through 6 {
        .avatar-#{$name}:nth-child(#{$i}) {
          z-index: $i;
        }
      }
    }
  }
  &.avatar-stacked-sparse {
    @each $name, $size in $avatar-element-size {
      @for $i from 1 through 6 {
        .avatar-#{$name}:nth-child(#{$i}) {
          @if $i == 1 {
            margin-left: 0;
          } @else {
            margin-left: -#{$size / 8};
          }
        }
      }
    }
  }
}

.avatar-grid-container {
  display: inline-block;
}

.avatar-grid-row {
  font-size: 0;
  @each $name, $size in $avatar-element-size {
    .avatar-#{$name} {
      margin-left: #{$size / 3};
    }
    .avatar-#{$name}:first-child {
      margin-left: 0;
    }
  }
  .avatar-grid-text {
    padding-top: 8px;
    color: $link;
    text-align: center;
    cursor: pointer;
    p {
      margin: 0;
    }
    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
}

@each $name, $size in $avatar-element-size {
  .avatar-grid-#{$name} {
    .avatar-grid-row {
      padding-top: #{$size / 3};
    }
    .avatar-grid-row:first-child {
      padding-top: 0;
    }
  }
}
