.#{$prefix}nav-tabs {
  margin-bottom: 24px;

  .#{$prefix}nav {
    &-item {
      margin: 8px 0;
    }

    &-link {
      position: relative;
      padding: 8px 16px;
      color: $slate;
      border: none;
      @include border-radius(4px);

      &:hover {
        color: $dark;
      }

      &.active {
        font-weight: 500;
        &::after {
          position: absolute;
          bottom: -9px;
          left: 0;
          width: 100%;
          height: 4px;
          content: "";
          background-color: $dark;
          border-radius: 2px 2px 0 0; // stylelint-disable-line property-blacklist
        }
      }

      @each $color, $hex in $theme-colors {
        &.active {
          &.theme-#{$color} {
            &::after {
              background-color: #{$hex};
            }
          }
        }
      }

      &.disabled {
        color: $muted;
        pointer-events: auto;
        cursor: not-allowed;
      }
    }
  }

  .#{$prefix}dropdown-menu {
    margin-top: 11px;
    @include border-radius(4px);

    .#{$prefix}dropdown-item {
      &.active {
        color: inherit;
        background-color: inherit;
      }
    }
  }
}
