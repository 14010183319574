// Badges
$badge-font-size:                   $font-size-base;
$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   4px;
$badge-padding-x:                   8px;
$badge-border-radius: 4px;
$badge-transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$badge-pill-padding-x: .6em;
$badge-pill-border-radius:          10rem;
$badge-focus-width: .2em;
