.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  font-weight: $font-weight-bold;
  text-align: center;
  &.warning { // this variant will be deprecated in next version
    color: $dark;
    background-color: $warning;
    .ic {
      color: $dark;
    }
  }
  &.error { // this variant will be deprecated in next version
    color: $white;
    background-color: $danger;
    .ic {
      color: $white;
    }
  }
  @each $color, $value in $theme-colors {
    &.#{$prefix}banner-#{$color} {
      @include badge-variant($value);
    }
  }
  .ic {
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 18px;
    color: inherit;
  }
}

.banner-text,
.banner-link {
  display: inline-block;
  padding-left: 8px;
}

.banner-link {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  a {
    color: inherit;
  }
}
